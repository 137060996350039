import React, { useEffect, useState } from "react";
import Link from "next/link";

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuHeight, setMenuHeight] = useState("");
  const burgerMenuOperner = () => {
    setMenuOpen(!menuOpen);
    setBodyClass();
  };

  useEffect(() => {
    if (!menuOpen) {
      document.body.classList.remove("overflow-hidden");
    }
  }, [menuOpen]);

  const setBodyClass = () => {
    var body = document.body;
    body.classList.add("overflow-hidden");
  };
  return (
    <>
      <div className="bg-right-top mobile-menu ">
        <div className="mx-auto">
          <div className="justify-between">
            <div className="flex justify-between items-center px-5 py-4">
              <div className="flex" id="burger" onClick={burgerMenuOperner}>
                <img
                  alt="mobile-menu-icon"
                  src="/images/bars.svg"
                  className="h-7 w-5 cursor-pointer"
                />
              </div>
              <div>
                <Link href="/">
                  <a>
                    <img
                      className="h-8 w-16"
                      src="/images/dsi_logo.svg"
                      alt="Dynamic Solution Innovators Ltd."
                    />
                  </a>
                </Link>
              </div>
            </div>

            <div
              className={`h-[calc(100vh-64px)] overflow-y-scroll backdrop-blur-xl bg-black/75 w-full fixed z-10 top-16 grid ${
                !menuOpen ? "hidden" : ""
              }`}
              id="menu"
            >
              <div className="px-10 py-16 w-full absolute z-10 grid">
                <div className="flex flex-col">
                  <Link href="/works">
                    <a
                      onClick={() => setMenuOpen(false)}
                      className="font-headline text-3xl font-semibold text-white border-b border-gray-600 py-5"
                    >
                      How we work
                    </a>
                  </Link>
                  <Link href="/services">
                    <a
                      onClick={() => setMenuOpen(false)}
                      className=" font-headline text-3xl font-semibold text-white border-b border-gray-600 py-5"
                    >
                      Services
                    </a>
                  </Link>
                  <Link href="/projects" className="py-0">
                    <a
                      onClick={() => setMenuOpen(false)}
                      className=" font-headline text-3xl font-semibold text-white border-b border-gray-600 py-5"
                    >
                      Projects
                    </a>
                  </Link>
                  <Link href="/vlsi" className="py-0">
                    <a
                      onClick={() => setMenuOpen(false)}
                      className=" font-headline text-3xl font-semibold text-white border-b border-gray-600 py-5"
                    >
                      VLSI
                    </a>
                  </Link>
                  <Link href="/casestudies">
                    <a
                      onClick={() => setMenuOpen(false)}
                      className=" font-headline text-3xl font-semibold text-white border-b border-gray-600 py-5"
                    >
                      Case Studies
                    </a>
                  </Link>
                  <Link href="/people/1">
                    <a
                      onClick={() => setMenuOpen(false)}
                      className="hidden font-headline text-3xl font-semibold text-white border-b border-gray-600 py-5"
                    >
                      People
                    </a>
                  </Link>
                  <Link href="/campus">
                    <a
                      onClick={() => setMenuOpen(false)}
                      className="font-headline text-3xl font-semibold text-white border-b border-gray-600 py-5"
                    >
                      Campus
                    </a>
                  </Link>
                  <Link href="/news">
                    <a
                      onClick={() => setMenuOpen(false)}
                      className="font-headline text-3xl font-semibold text-white border-b border-gray-600 py-5"
                    >
                      News
                    </a>
                  </Link>
                  <Link href="/blogs">
                    <a
                      onClick={() => setMenuOpen(false)}
                      className="font-headline text-3xl font-semibold text-white border-b border-gray-600 py-5"
                    >
                      Blog
                    </a>
                  </Link>
                  <Link href="/odoo">
                    <a
                      onClick={() => setMenuOpen(false)}
                      className="font-headline text-3xl font-semibold text-white border-b border-gray-600 py-5"
                    >
                      Odoo
                    </a>
                  </Link>
                  <Link href="https://app.hrythmic.com/recruit/openings/company/dsinnovators/">
                    <a
                      className="font-headline text-3xl font-semibold text-white border-b border-gray-600 py-5"
                      target="_blank"
                    >
                      Career Opportunity
                    </a>
                  </Link>
                </div>
              </div>
              <div className="flex flex-auto"></div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
