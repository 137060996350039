import Link from "next/link";
import Image from "next/image";
import dynamic from "next/dynamic";

const MailToComp = dynamic(() => import("../components/Home/MailtoComp"), {
    ssr: false,
});

const Footer = () => {
    return (
        <div className="w-full py-10 bg-black md:py-14">
            <div className="hidden px-5 md:grid mid-home-column">
                <div className="flex justify-between">
                    <div className="flex justify-between">
                        <div className="flex flex-col pr-10 text-left max-w-max">
                            <h2 className="pb-2 text-xl font-extrabold text-white font-headline">
                                USA Office
                            </h2>
                            <p className="text-base font-normal text-gray-300 font-headline">
                                8201 164th Ave NE, Suite 200
                            </p>
                            <p className="text-base font-normal text-gray-300 font-headline">
                                Redmond WA 98052
                            </p>
                            <p className="text-base font-normal text-gray-300 font-headline">
                                United States of America
                            </p>
                            <p className="text-base font-normal text-gray-300 font-headline">
                                +1 (404) 287-0730
                            </p>
                        </div>
                        <div className="flex flex-col px-10 text-left border-l-4 border-blue-500 max-w-max">
                            <h2 className="pb-2 text-xl font-extrabold text-white font-headline">
                                Bangladesh Office
                            </h2>
                            <p className="text-base font-normal text-gray-300 font-headline">
                                House 177, Lane 2, New DOHS
                            </p>
                            <p className="text-base font-normal text-gray-300 font-headline">
                                Mohakhali, Dhaka 1206
                            </p>
                            <p className="text-base font-normal text-gray-300 font-headline">
                                Bangladesh
                            </p>
                            <p className="text-base font-normal text-gray-300 font-headline">
                                +88 02222287041
                            </p>
                            <p className="text-base font-normal text-gray-300 font-headline">
                                +88-01730-03-74-00
                            </p>
                        </div>
                        <div className="flex flex-col pl-10 text-left border-l-4 border-blue-500 max-w-max">
                            <h2 className="pb-2 text-xl font-extrabold text-white font-headline">
                                European Liaison Office
                            </h2>
                            <p className="text-base font-normal text-gray-300 font-headline">
                                32 Swansea Court
                            </p>
                            <p className="text-base font-normal text-gray-300 font-headline">
                                London
                            </p>
                            <p className="text-base font-normal text-gray-300 font-headline">
                                E16 2RT
                            </p>
                             <p className="text-base font-normal text-gray-300 font-headline">
                                United Kingdom
                            </p>
                            <p className="text-base font-normal text-gray-300 font-headline">
                                +44 7850 051037
                            </p>
                        </div>
                    </div>

                    <div className="text-right">
                        <Link href="/">
                            <a title="Go back to Home">
                                <Image
                                    className="text-right"
                                    alt="Logo of Dynamic Solution Innovators Ltd."
                                    src="/images/logo-footer.png"
                                    width="100"
                                    height="143"
                                    layout="intrinsic"
                                    objectFit="fill"
                                    quality={100}
                                />
                            </a>
                        </Link>
                    </div>
                </div>
                <div className="flex items-end justify-between mt-9">
                    <div className="items-left">
                        <p className="text-base font-semibold text-gray-300 font-headline">
                            Drop us a line at{" "}
                            <span className="text-base italic font-bold text-white font-headline">
                <MailToComp/>
              </span>
                        </p>
                    </div>
                    <div className="items-right">
                        <div className="flex justify-end gap-2">
                            <div className="relative group">
                                <Link href="https://www.facebook.com/DynamicSolutionInnovators">
                                    <a target="_blank">
                                        <img
                                            className="relative w-6 h-6 opacity-100"
                                            alt="facebook-icon"
                                            src="/images/fb.svg"
                                        />
                                    </a>
                                </Link>
                                <Link href="https://www.facebook.com/DynamicSolutionInnovators">
                                    <a target="_blank">
                                        <img
                                            className="absolute top-0 w-6 h-6 opacity-0 group-hover:opacity-100"
                                            alt="facebook-icon"
                                            src="/images/fb-white.svg"
                                        />
                                    </a>
                                </Link>
                            </div>
                            <div className="relative group">
                                <Link href="https://www.linkedin.com/company/dsinnovators">
                                    <a target="_blank">
                                        <img
                                            className="w-6 h-6 opacity-100"
                                            alt="linkdln-icon"
                                            src="/images/ln.svg"
                                        />
                                    </a>
                                </Link>
                                <Link href="https://www.linkedin.com/company/dsinnovators">
                                    <a target="_blank">
                                        <img
                                            className="absolute top-0 w-6 h-6 opacity-0 group-hover:opacity-100"
                                            alt="linkdln-icon"
                                            src="/images/ln-white.svg"
                                        />
                                    </a>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* mobile footer */}
            <div className="grid px-5 mid-home-column mid-column md:hidden">
                <div className="grid grid-cols-1">
                    <div className="text-left">
                        <h2 className="pb-2 text-xl font-extrabold text-white font-headline">
                            USA Office
                        </h2>
                        <p className="text-base font-normal text-gray-300 font-headline">
                            8201 164th Ave NE, Suite 200
                        </p>
                        <p className="text-base font-normal text-gray-300 font-headline">
                            Redmond WA 98052
                        </p>
                        <p className="text-base font-normal text-gray-300 font-headline">
                            United States of America
                        </p>
                        <p className="text-base font-normal text-gray-300 font-headline">
                            +1 (404) 287-0730
                        </p>
                    </div>
                    <div className="text-left border-t-4 border-blue-500 pt-9 mt-9">
                        <h2 className="pb-2 text-xl font-extrabold text-white font-headline">
                            Bangladesh Office
                        </h2>
                        <p className="text-base font-normal text-gray-300 font-headline">
                            House 177, Lane 2, New DOHS
                        </p>
                        <p className="text-base font-normal text-gray-300 font-headline">
                            Mohakhali, Dhaka 1206
                        </p>
                        <p className="text-base font-normal text-gray-300 font-headline">
                            Bangladesh
                        </p>
                        <p className="text-base font-normal text-gray-300 font-headline">
                            +88 02222287041
                        </p>
                        <p className="text-base font-normal text-gray-300 font-headline">
                            +88017300-374-00
                        </p>
                    </div>
                    <div className="text-left border-t-4 border-blue-500 pt-9 mt-9">
                        <h2 className="pb-2 text-xl font-extrabold text-white font-headline">
                            European Liaison Office
                        </h2>
                        <p className="text-base font-normal text-gray-300 font-headline">
                            32 Swansea Court
                        </p>
                        <p className="text-base font-normal text-gray-300 font-headline">
                            London
                        </p>
                        <p className="text-base font-normal text-gray-300 font-headline">
                            E16 2RT
                        </p>
                         <p className="text-base font-normal text-gray-300 font-headline">
                            United Kingdom
                        </p>
                         <p className="text-base font-normal text-gray-300 font-headline">
                            +44 7850 051037
                        </p>
                    </div>
                </div>
                <div className="grid items-end justify-between grid-cols-2 grid-rows-1 pt-12">
                    <div className="items-left">
                        <div className="flex gap-2 py-2">
                            <div className="relative group">
                                <Link href="https://www.facebook.com/DynamicSolutionInnovators">
                                    <a target="_blank">
                                        <img
                                            className="relative w-6 h-6 opacity-100 "
                                            alt="facebook-icon"
                                            src="/images/fb.svg"
                                        />
                                    </a>
                                </Link>
                                <Link href="https://www.facebook.com/DynamicSolutionInnovators">
                                    <a target="_blank">
                                        <img
                                            className="absolute top-0 w-6 h-6 opacity-0 group-hover:opacity-100"
                                            alt="facebook-icon"
                                            src="/images/fb-white.svg"
                                        />
                                    </a>
                                </Link>
                            </div>
                            <div className="relative group">
                                <Link href="https://www.linkedin.com/company/dsinnovators">
                                    <a target="_blank">
                                        <img
                                            className="w-6 h-6 opacity-100"
                                            alt="linkdln-icon"
                                            src="/images/ln.svg"
                                        />
                                    </a>
                                </Link>
                                <Link href="https://www.linkedin.com/company/dsinnovators">
                                    <a target="_blank">
                                        <img
                                            className="absolute top-0 w-6 h-6 opacity-0 group-hover:opacity-100"
                                            alt="linkdln-icon"
                                            src="/images/ln-white.svg"
                                        />
                                    </a>
                                </Link>
                            </div>
                        </div>
                        <p className="text-xs font-semibold text-gray-300 font-headline">
                            Drop us a line at
                        </p>
                        <p className="text-xs italic font-extrabold text-gray-400 font-headline">
                            <MailToComp/>
                        </p>
                    </div>
                    <div className="items-right">
                        <div className="justify-end text-right">
                            <Link href="/">
                                <a title="Go back to Home">
                                    <Image
                                        className="text-right w-28 h-36"
                                        alt="dsi logo"
                                        src="/images/logo-footer.png"
                                        width="100"
                                        height="143"
                                        layout="intrinsic"
                                        objectFit="fill"
                                        quality={100}
                                    />
                                </a>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
