import Navbar from "../components/navbar";
import MobileMenu from "../components/MobileMenu";
import Head from "next/head";
import Footer from "../components/footer";
import { Fragment } from "react";
import { useRouter } from "next/router";
import { vlsiCondition } from "../utils/utility";

const Layout = prop => {
  const router = useRouter();
  let splitted_array = router.pathname.split("/");
  let showMenu = vlsiCondition(splitted_array);
  return (
    <Fragment>
      <Head />
      <header className="hidden xl:block w-full bg-black sticky top-0 z-50">
        {showMenu ? <Navbar /> : " "}
      </header>
      <header className="block xl:hidden w-full bg-black sticky top-0 z-50">
        {showMenu ? <MobileMenu /> : " "}
      </header>
      <main>{prop.children}</main>
      <footer>{showMenu ? <Footer /> : " "}</footer>
    </Fragment>
  );
};

export default Layout;
