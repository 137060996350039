import React, { useEffect, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import dynamic from "next/dynamic";

const MailToComp = dynamic(() => import("../components/Home/MailtoComp"), {
  ssr: false,
});

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const burgerMenuOperner = () => {
    setMenuOpen(!menuOpen);
  };
  const router = useRouter();

  const mainMenus = [
    "works",
    "services",
    "projects",
    "vlsi",
    "casestudies",
    "people",
    "campus",
    "news",
    "blogs",
    "odoo"
  ];

  const getActive = () => {
    let path = router.pathname;

    let splittedArr = path.split("/");
    let arrWithoutEmpty, res;

    // when /blogs/1/more
    if (splittedArr.length > 2) {
      res = splittedArr.filter((item) => item != "");
    } else {
      // when /works or single path

      res = splittedArr.filter((item) => item != "");
    }

    arrWithoutEmpty = res[0];
    let currentMenu = null;

    if (mainMenus.includes(arrWithoutEmpty)) {
      currentMenu = arrWithoutEmpty;
    } else {
      currentMenu = "/";
    }
    return currentMenu;
  };

  const clearPreviousAll = () => {
    mainMenus.map((menu) => {
      let removeList = ["border-b-4", "border-yellow-btn"];
      let addList = [
        "hover:border-yellow-btn",
        "hover:border-b-4",
        "border-b-4",
        "border-transparent",
      ];
      removeList.map((item) => {
        document.getElementById(menu).classList.remove(item);
      });

      addList.map((item) => {
        document.getElementById(menu).classList.add(item);
      });
    });
  };

  const addClasses = (activeMenu) => {
    let addClassesList = ["border-b-4", "border-yellow-btn"];
    addClassesList.map((item) => {
      document.getElementById(activeMenu).classList.add(item);
    });
  };

  const removeClasses = (activeMenu) => {
    let removeClassesList = [
      "hover:border-yellow-btn",
      "border-transparent",
      "hover:border-yellow-btn",
    ];

    removeClassesList.map((item) => {
      document.getElementById(activeMenu).classList.remove(item);
    });
  };

  useEffect(() => {
    let activeMenu = getActive();
    clearPreviousAll();
    if (activeMenu !== "/" && activeMenu != null) {
      addClasses(activeMenu);
      removeClasses(activeMenu);
    }
  });

  return (
    <>
      <div className="mx-auto">
        <div className="flex">
          <div className="px-10 py-4">
            <Link href="/">
              <a title="Go back to Home">
                <img
                  className="w-20 h-12"
                  alt="Logo of Dynamic Solution Innovators Ltd."
                  src="/images/dsi_logo.svg"
                />
              </a>
            </Link>
          </div>
          <div className="flex mx-auto mid-home-column">
            <ul className="flex justify-between w-full pt-6 pl-2 mx-4 text-white">
              <li className="">
                <Link href="/works">
                  <a
                    className={
                      router.asPath === "/services"
                        ? "pb-6 flex border-b-4 border-yellow-btn font-headline font-normal text-xl"
                        : "pb-6 flex text-xl border-b-4 border-transparent font-headline font-normal"
                    }
                    id="works"
                  >
                    How We Work
                  </a>
                </Link>
              </li>
              <li className="">
                <Link href="/services">
                  <a
                    className={
                      router.asPath === "/services"
                        ? "pb-6 flex border-b-4 border-yellow-btn font-headline font-normal text-xl"
                        : "pb-6 flex text-xl border-b-4 border-transparent font-headline font-normal"
                    }
                    id="services"
                  >
                    Services
                  </a>
                </Link>
              </li>
              <li className="">
                <Link href="/projects">
                  <a
                    className={
                      router.asPath === "/projects"
                        ? "pb-6 flex border-b-4 border-yellow-btn font-headline font-normal text-xl"
                        : "pb-6 flex text-xl border-b-4 border-transparent font-headline font-normal"
                    }
                    id="projects"
                  >
                    Projects
                  </a>
                </Link>
              </li>
              <li className="">
                <Link href="/vlsi">
                  <a
                    className={
                      router.asPath === "/vlsi"
                        ? "pb-6 flex border-b-4 border-yellow-btn font-headline font-normal text-xl"
                        : "pb-6 flex text-xl border-b-4 border-transparent font-headline font-normal"
                    }
                    id="vlsi"
                  >
                    VLSI
                  </a>
                </Link>
              </li>
              <li className="">
                <Link href="/casestudies">
                  <a
                    className={
                      router.asPath === "/casestudies"
                        ? "pb-6 flex border-b-4 border-yellow-btn font-headline font-normal text-xl"
                        : "pb-6 flex text-xl border-b-4 border-transparent font-headline font-normal"
                    }
                    id="casestudies"
                  >
                    Case Studies
                  </a>
                </Link>
              </li>
              <li className="hidden">
                <Link href="/people/1">
                  <a
                    className={
                      router.asPath === "/people/1"
                        ? "pb-6 flex border-b-4 border-yellow-btn font-headline font-normal text-xl"
                        : "pb-6 flex text-xl border-b-4 border-transparent font-headline font-normal"
                    }
                    id="people"
                  >
                    People
                  </a>
                </Link>
              </li>
              <li className="">
                <Link href="/campus">
                  <a
                    className={
                      router.asPath === "/campus"
                        ? "pb-6 flex border-b-4 border-yellow-btn font-headline font-normal text-xl"
                        : "pb-6 flex text-xl border-b-4 border-transparent font-headline font-normal"
                    }
                    id="campus"
                  >
                    Campus
                  </a>
                </Link>
              </li>
              <li className="">
                <Link href="/news">
                  <a
                    className={
                      router.asPath === "/news"
                        ? "pb-6 flex border-b-4 border-yellow-btn font-headline font-normal text-xl"
                        : "pb-6 flex text-xl border-b-4 border-transparent font-headline font-normal"
                    }
                    id="news"
                  >
                    News
                  </a>
                </Link>
              </li>
              <li className="">
                <Link href="/blogs">
                  <a
                    className={
                      router.asPath === "/blogs"
                        ? "pb-6 flex border-b-4 border-yellow-btn font-headline font-normal text-xl"
                        : "pb-6 flex text-xl border-b-4 border-transparent font-headline font-normal"
                    }
                    id="blogs"
                  >
                    Blog
                  </a>
                </Link>
              </li>
              <li className="">
                <Link href="/odoo">
                  <a
                    className={
                      router.asPath === "/odoo"
                        ? "pb-6 flex border-b-4 border-yellow-btn font-headline font-normal text-xl"
                        : "pb-6 flex text-xl border-b-4 border-transparent font-headline font-normal"
                    }
                    id="odoo"
                  >
                    Odoo
                  </a>
                </Link>
              </li>
            </ul>
          </div>
          <div className="py-4 pr-10">
            <a
              className="flex px-4 py-2 font-medium text-center text-white border border-white hover:bg-white hover:text-black sm:text-xs md:text-sm lg:text-lg font-headline"
              href="https://app.hrythmic.com/recruit/openings/company/dsinnovators/"
              target="_blank"
            >
              Career Opportunity
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
